<template>
  <div>
    <form
      ref="form"
      class="refund-component"
      @submit.stop.prevent="()=>{}"
    >
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Amount:"
        label-for="amount-input"
      >
        <b-input-group
          class="input-group-merge"
          prepend="R$"
        >
          <b-form-input
            id="amount-input"
            :value="amount"
            type="number"
            :disabled="true"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Avaliable Amount:"
        label-for="avaliable-amount-input"
      >
        <b-input-group
          class="input-group-merge"
          prepend="R$"
        >
          <b-form-input
            id="avaliable-amount-input"
            :value="avaliableAmount"
            type="number"
            :disabled="true"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Merchant Ref:"
        label-for="merchant-ref-input"
      >
        <b-form-input
          id="merchant-ref-input"
          :value="merchantRef"
          autocomplete="off"
          :disabled="true"
        />
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Refund Amount:"
        label-for="refund-amount-input"
      >
        <validation-provider
          #default="{ errors }"
          name="Refund Amount"
          rules="required|validRefund"
        >
          <b-input-group
            :class="errors.length > 0 ? 'is-invalid':null"
            class="input-group-merge"
            prepend="R$"
          >
            <b-form-input
              id="refund-amount-input"
              :value="refundAmount"
              type="number"
              :state="errors.length > 0 ? false:null"
              @input="(val) => $emit('update:refundAmount', val)"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Reason:"
        label-for="reason-select"
      >
        <validation-provider
          #default="{ errors }"
          name="Reason"
          rules="required"
        >
          <v-select
            id="reason-select"
            :value="reason"
            :options="reasonList"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:reason', val)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Other Reason:"
        label-for="other-reason-input"
        invalid-feedback="Password is required"
      >
        <validation-provider
          v-if="reason==='Other'"
          #default="{ errors }"
          name="Other Reason"
          rules="required"
        >
          <b-form-textarea
            id="other-reason-input"
            :value="otherReason"
            :state="errors.length > 0 ? false:null"
            placeholder="Enter the reason for providing a refund"
            rows="3"
            @input="(val) => $emit('update:otherReason', val)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <b-form-textarea
          v-else
          id="other-reason-input"
          :value="otherReason"
          placeholder="Enter the reason for providing a refund"
          rows="3"
          @input="(val) => $emit('update:otherReason', val)"
        />
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Password"
        label-for="password-input"
      >
        <validation-provider
          #default="{ errors }"
          name="Password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="password-input"
              :value="password"
              :type="passwordFieldType"
              :state="errors.length > 0 ? false:null"
              placeholder="Enter Password"
              autocomplete="new-password"
              @input="(val) => $emit('update:password', val)"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </form>
  </div>
</template>

<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default defineComponent({
  props: {
    amount: { type: String, default: '' },
    avaliableAmount: { type: String, default: '' },
    merchantRef: { type: String, default: '' },
    refundAmount: { type: String, default: '' },
    reason: { type: String, default: '' },
    otherReason: { type: String, default: '' },
    password: { type: String, default: '' },
    reasonList: { type: Array, default: () => [] },
  },
  setup(props) {
    extend('validRefund', {
      validate(value) {
        return Number(value) <= Number(props.avaliableAmount)
      },
      message: 'Refund amount must be less than or equal to Avaliable amount.',
    })
    const passwordFieldType = ref('password')
    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
    }
    const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    return {
      required, passwordToggleIcon, passwordFieldType, togglePasswordVisibility,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BModal,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// .refund-component {

// }
</style>
