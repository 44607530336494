<template>
  <div class="account-detail">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <h3>Transaction Details</h3>
      <b-card
        title="Basic Info"
        class="mt-2"
      >
        <hr>
        <div class="basic-content">
          <div>
            <b-card-text class="mt-2">
              <span>Transaction ID：</span><span>{{ info.transaction_id }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>Product name：</span><span>{{ info.product_name }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>Update Time：</span><span>{{ updateDate(info.updated_at_ms, $route.query.timezone) }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>Processing fee：</span><span>{{ info.processing_fee }}</span>
            </b-card-text>
          </div>
          <div>
            <b-card-text class="mt-2">
              <span>Transaction status：</span><span>{{ info.status_text }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>Currency：</span><span>{{ info.currency }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>Merchant Reference：</span><span>{{ info.out_trade_no }}</span>
            </b-card-text>
          </div>
          <div>
            <b-card-text class="mt-2">
              <span>Amount：</span><span>{{ info.amount }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>Create Time：</span><span>{{ updateDate(info.created_at_ms, $route.query.timezone) }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>Transaction Type：</span><span>{{ info.transaction_type_text }}</span>
            </b-card-text>
          </div>
        </div>
      </b-card>
      <b-card
        title="User Info"
        class="mt-2"
      >
        <hr>
        <div>
          <div>
            <b-card-text class="mt-2">
              <span>Name：</span><span>{{ info.user_name }}</span>
            </b-card-text>
            <b-card-text class="mt-2">
              <span>User ID：</span><span>{{ info.user_id }}</span>
            </b-card-text>
          </div>
        </div>
      </b-card>
      <b-card
        title="Record"
        class="mt-2"
      >
        <hr>
        <b-button
          variant="primary"
          class="refund-btn"
          @click="refund"
        >Refund</b-button>
        <table-list
          :table-data.sync="tableData"
          :list.sync="tableList"
          :page-num.sync="currentPage"
          :total-list.sync="totalList"
        />
      </b-card>
    </b-overlay>
    <b-modal
      ref="refund-modal"
      :no-close-on-backdrop="true"
      title="Manage Refund"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      size="lg"
      @show="resetRefundModal"
      @hidden="resetRefundModal"
      @ok="handleRefundOk"
    >
      <b-overlay
        variant="white"
        :show="refundLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <validation-observer ref="refundRules">
          <refund-component
            :amount="reAmount"
            :avaliable-amount="reAvaliableAmount"
            :merchant-ref="reMerchantRef"
            :refund-amount.sync="reRefundAmount"
            :reason.sync="reReason"
            :other-reason.sync="reOtherReason"
            :password.sync="rePassword"
            :reason-list="reasonList"
          />
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, watch, onMounted,
} from '@vue/composition-api'
import {
  BButton, BOverlay, BCard, BCardText,
} from 'bootstrap-vue'
import { showToast, updateDate, aseEncrypt } from '@/libs/utils'
import { ValidationObserver } from 'vee-validate'
import RefundComponent from './components/RefundComponent.vue'

export default defineComponent({
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { $api, $route } = proxy
    // 列表
    const showLoading = ref(false)
    const refundLoading = ref(false)

    const currentPage = ref(1)
    const tableList = ref([])
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'transaction_id', label: 'Status' },
        { key: 'amount', label: 'Date' },
        { key: 'processing_fee', label: 'Description' },
        { key: 'currency', label: 'Refund Amount' },
        { key: 'status_text', label: 'Available Amount' },
      ],
      templates: [],
    })
    const info = ref({})
    const fetchTransactionDetail = async () => {
      showLoading.value = true
      const res = await $api.transactionDetail({ transaction_id: $route.query.id })
      showLoading.value = false
      const { code, data, message } = res.data
      if (code === 200) {
        info.value = data
      } else {
        showToast({ proxy: this, title: 'danger', message })
      }
    }
    // refund
    const reAmount = ref('')
    const reAvaliableAmount = ref('')
    const reMerchantRef = ref('')
    const reRefundAmount = ref('')
    const reReason = ref('')
    const reOtherReason = ref('')
    const rePassword = ref('')
    const reasonList = ref([
      { label: 'No longer required', value: 'No longer required' },
      { label: 'Product damaged or deffective', value: 'Product damaged or deffective' },
      { label: 'Wrong size, fit or compatabillity', value: 'Wrong size, fit or compatabillity' },
      { label: 'Unacceptable delivery time', value: 'Unacceptable delivery time' },
      { label: 'Product does not match description', value: 'Product does not match description' },
      { label: 'Purchase was fraudulent', value: 'Purchase was fraudulent' },
      { label: 'Other', value: 'Other' },
    ])
    const refund = () => {
      proxy.$refs['refund-modal'].show()
      reAmount.value = info.value.amount
      reAvaliableAmount.value = info.value.refund_amount
      reMerchantRef.value = info.value.out_trade_no
    }
    const resetRefundModal = () => {
      reAmount.value = ''
      reAvaliableAmount.value = ''
      reMerchantRef.value = ''
      reRefundAmount.value = ''
      reReason.value = ''
      reOtherReason.value = ''
      rePassword.value = ''
    }
    const handleRefundOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      proxy.$refs.refundRules.validate().then(async success => {
        if (success) {
          const params = {
            transaction_id: info.value.transaction_id,
            refund_amount: reRefundAmount.value,
            reason: reReason.value,
            password: aseEncrypt(rePassword.value),
          }
          if (reReason.value === 'Other') {
            params.reason = reOtherReason.value
          }
          refundLoading.value = true
          const res = await $api.transactionRefund(params).catch(() => { refundLoading.value = false })
          refundLoading.value = false
          const { code, message } = res.data
          if (code === 200) {
            fetchTransactionDetail()
            proxy.$refs['refund-modal'].hide()
          } else {
            showToast({ proxy: this, title: 'danger', message })
          }
        }
      })
    }
    onMounted(() => {
      fetchTransactionDetail()
    })
    return {
      showLoading,
      refundLoading,
      currentPage,
      tableList,
      totalList,
      tableData,

      info,
      fetchTransactionDetail,
      updateDate,

      reAmount,
      reAvaliableAmount,
      reMerchantRef,
      reRefundAmount,
      reReason,
      reOtherReason,
      rePassword,
      reasonList,
      refund,
      resetRefundModal,
      handleRefundOk,

    }
  },
  components: {
    BButton, BOverlay, BCard, BCardText, RefundComponent, ValidationObserver,
  },
})
</script>

<style lang="scss" scoped>
.account-detail {
  padding: 3rem 4rem;
  margin: 0 auto;
  min-height: 400px;
  .basic-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .card-text {
      margin-right: 100px;
    }
  }
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
  .refund-btn {
    position: absolute;
    right: 40px;
    top: 15px;
  }
}
</style>
